<template>
    <div class="info">
      <top-bar :title="'处理小事'" :left="true"></top-bar>

      <van-popup v-model="resultShow" position="bottom"  >
        <van-picker title="处理结果" show-toolbar :columns="resultOptions" value-key="label" @confirm="resultConfirm" @cancel="resultShow = false"  />
      </van-popup>
      <van-popup v-model="typeShow" position="bottom"  >
        <van-picker title="问题类别" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm" @cancel="typeShow = false"  />
      </van-popup>
      <van-popup v-model="gridShow" position="bottom"  >
        <van-picker title="所在网格" show-toolbar :columns="gridList" value-key="label" @confirm="gridConfirm" @cancel="gridShow = false"  />
      </van-popup>
      <van-popup v-model="statusShow" position="bottom"  >
        <van-picker title="驾驶舱选项" show-toolbar :columns="statusList" value-key="label" @confirm="statusConfirm" @cancel="statusShow = false"  />
      </van-popup>
      <van-popup v-model="managerShow" position="bottom"  >
        <van-picker title="推送到" show-toolbar :columns="managerList" value-key="label" @confirm="managerConfirm" @cancel="managerShow = false"  />
      </van-popup>

      <van-cell >
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">事件信息</span>
        </template>
      </van-cell>
      <van-cell title="所在网格"  :value="gridIdValue" @click="gridShow = !gridShow"/>
<!--      <van-cell title="问题类别"  :value="typeValue" @click="typeShow = !typeShow"/>-->
      <van-cell title="处理状态"  :value="resultValue" @click="resultShow = !resultShow"/>
      <van-cell title="驾驶舱选项"  :value="driveStatusValue" @click="statusShow = !statusShow"/>
<!--      <van-cell title="推送到其他人员" :value="managerValue" @click="managerShow = !managerShow"/>-->
      <van-field v-model="dataForm.content" label="处理描述" placeholder="请输入" input-align="right" rows="4" type="textarea"/>
      <div class="uploadBox">
        <van-uploader v-model="fileList"  style="padding:20px" v-if="dataForm.status == 0 || dataForm.status == 30" @delete="uploaddel" :after-read="uploadRead"/>
      </div>
      <van-row class="btns">
        <van-col :span="24" style="padding-top: 80px">
          <van-button type="info" size="large" round @click="submit">确定处理</van-button>
        </van-col>
      </van-row>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getImageStream} from '@/utils/index'

import { mapState,mapMutations } from 'vuex'
export default {
  data () {
    return {
      resultShow: false,
      typeShow: false,
      managerShow: false,
      gridShow: false,
      statusShow: false,
      value: '',
      detailData: {},
      resultValue: '请选择',
      typeValue: '请选择',
      managerValue: '请选择',
      gridIdValue: '请选择',
      driveStatusValue: '请选择',
      resultOptions:[
        { label: "无法处理", value: "0" },
        { label: "推送", value: "10" },
        { label: "处理中", value: "20" },
        { label: "处理完成", value: "30" }
      ],
      typeList:[],
      managerList: [],
      gridList: [],
      statusList: [
        { label: "显示", value: 0 },
        { label: "不显示", value: 1 },
      ],
      dataForm:{
        status:'',
        type:'',
        manager: '',
        id: '',
        fileMessages:[],
        content: '',
        gridId: '',
        driveStatus: '',
        solveDes: ''
      },
      communityId: '',
      fileList:[]
    }
  },
  // computed:{...mapState(['problemData'])},
  components :{
    topBar
  },
  methods: {
    getDetail (problemId) {
      console.log(this.dataForm.gridId)
      // this.communityId = this.problemData.communityId
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: problemId
        })
      }).then(({data})=> {
          if (data.code == 0) {
            this.typeValue = data.entityVO.typeStr
            this.dataForm.id =  data.entityVO.id
            this.dataForm.type = data.entityVO.type
            this.gridIdValue = data.entityVO.gridStr
            this.dataForm.gridId = data.entityVO.gridId
            this.driveStatusValue = data.entityVO.driveStatus ===0 ? '显示' : '不显示'
            this.dataForm.driveStatus = data.entityVO.driveStatus
            this.dataForm.content = data.entityVO.solveDes
            this.resultOptions.forEach((item,index)=> {
              if(item.value == data.entityVO.status) {
                  this.resultValue = this.resultOptions[index].label
                  this.dataForm.status = this.resultOptions[index].value
              }
            })
          this.$toast.clear()
          } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
          }
      })
    },
    submit () {
      // debugger
      if (this.dataForm.type == '') {return this.$toast.fail('失败:请先选择事件类别');}
      if (this.dataForm.status == '') {return this.$toast.fail('失败:请先选择处理结果');}
      if (this.dataForm.gridId == '') {return this.$toast.fail('失败:请先选择所在网格');}
      // if (this.dataForm.manager == '' && this.dataForm.status == 10) {return this.$toast.fail('失败:请先选择推送人员');}
      if (this.dataForm.content == '' && this.dataForm.status == 0 || this.dataForm.status == 0) {return this.$toast.fail('失败:请先输入处理描述');}
      this.$http({
          url: this.$http.adornUrl('/wxapp/problem/update'),
          method: 'post',
          data: this.$http.adornData({
            content: this.dataForm.content,
            fileMessages: this.dataForm.fileMessages,
            id: this.dataForm.id,
            solveTarget: this.dataForm.manager,
            status: this.dataForm.status,
            type: this.dataForm.type,
            gridId: this.dataForm.gridId,
            driveStatus: this.dataForm.driveStatus,
          },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.$toast.success({message:'处理成功'})
            this.$router.push('/issues')
          } else {
          this.$toast.fail(data.msg);
          }
      })
    },
    getType() {
      this.$http({
          url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
          method: 'post',
          params: this.$http.adornParams({
            orgId: this.$orgId,
            code: 'problemType'
          },false)
      }).then(({data})=> {
          if (data.code == 0) {
              this.typeList = data.dicts
          } else {
          this.$toast.fail(data.msg);
          }
      })
    },
    getManager() {
      this.$http({
          url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
          method: 'post',
          params: this.$http.adornParams({
            orgId: this.$orgId,
            code: 'managerType'
          },false)
      }).then(({data})=> {
          if (data.code == 0) {
              this.managerList = data.dicts
          } else {
          this.$toast.fail(data.msg);
          }
      })
    },
    // 获取网格列表
    getGrids () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp//grid/info/listByUser`),
        method: 'get',
        params: this.$http.adornParams({
          'community': this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.gridList = data.grids
        } else {
          this.$message.error(data.msg)
        }
      })
    },
        // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.dataForm.fileMessages.splice(i, 1)
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
      let formdata = new FormData();
      formdata.append("file", item.file);
      formdata.append("path", 'files/problem/temporary');
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/upload'),
        method: 'post',
        data: formdata
      }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.dataForm.fileMessages.push(data.fileMessage)
            return true
          } else {
          this.$toast.fail(data.msg);
          }
        })
      })
    },
    resultConfirm(val) {
      this.resultValue = val.label
      this.dataForm.status = val.value
      this.resultShow = false
    },
    typeConfirm(val) {
      this.typeValue = val.label
      this.dataForm.type = val.value
      this.typeShow = false
    },
    managerConfirm(val) {
      this.managerValue = val.label
      this.dataForm.manager = val.value
      this.managerShow = false
    },
    gridConfirm(val) {
      this.gridIdValue = val.label
      this.dataForm.gridId = val.value
      this.gridShow = false
    },
    statusConfirm(val) {
      this.driveStatusValue = val.label
      this.dataForm.driveStatus = val.value
      this.statusShow = false
    }
  },
  created() {
    let problemId = this.$route.query.id
    // this.communityId = this.$route.query.communityId
    this.getType()
    this.getManager()
    this.getDetail(problemId)
    this.getGrids()
  }
}
</script>
<style>
  a {
  color: rgb(4, 50, 255);
}
.blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
    .van-field__control {
      text-align: right ;
    }
</style>
<style lang="scss" scoped>
.info {
  padding-bottom: 150px;
}
.cont {
  .van-cell__value {
    width: 500px;
    flex: 2;
  }
}
  .van-divider{
    border-color: #000;
  }
</style>
